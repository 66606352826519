<template>
  <div v-if="mostrar" class="tarjeta-parent">
    <div
      class="tarjeta tarjeta-body"
      style="background-color: #004a96"
      v-show="$usuario.rolCYO != null"
    >
      <div class="tarjeta-img">
        <img src="../../assets/img/tarjetas/CYO.png" />
      </div>
      <div>
        <label class="tarjeta-titulo">{{ titulo }}</label>
        <label class="tarjeta-descripcion">{{ subtitulo }}</label>
      </div>
      <img
        class="tarjeta-link"
        src="../../assets/img/botones/flecha.png"
        @click="ir()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "cyo",
  components: {},
  props: {},
  data() {
    return {
      mostrar: false,
      titulo: "",
      subtitulo: "",
    };
  },
  created() {},
  mounted() {
    this.getCYO();
  },
  computed: {},
  methods: {
    ir() {
      let token = localStorage.getItem("token");
      window.location.href = this.$cyo_url + "/landing/" + token;
    },
    getCYO() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/cyo/facturas/pendientes")
        .then(function (response) {
          console.log(response);
          if (response.data != null && response.data.length > 0) {
            let liquidaciones = response.data.filter(
              (r) => r.type == "Liquidacion"
            );
            let adquisiciones = response.data.filter(
              (r) => r.type == "Adquisicion"
            );
            let fungibles = response.data.filter((r) => r.type == "Fungible");
            let distribuidores = [];
            response.data.forEach((r) => {
              if (
                !distribuidores.some((d) => d.nombre == r.distribuidor.nombre)
              ) {
                distribuidores.push(r.distribuidor);
              }
            });

            let text = "Tenés ";
            if (liquidaciones.length > 0) {
              text = text + liquidaciones.length + " liquidaciones, ";
            }
            if (adquisiciones.length > 0) {
              text = text + adquisiciones.length + " adquisiciones, ";
            }
            if (fungibles.length > 0) {
              text = text + fungibles.length + " fungibles, ";
            }
            that.titulo = text.substr(0, text.length - 2);
            let pos = that.titulo.lastIndexOf(", ");
            that.titulo =
              that.titulo.substring(0, pos) +
              " y" +
              that.titulo.substring(pos + 1);

            that.subtitulo = "El cliente " + distribuidores[0].nombre;
            if (distribuidores.length > 1) {
              that.subtitulo =
                that.subtitulo +
                " y " +
                distribuidores.length +
                " más, están esperando la aprobación de sus cargas";
            } else {
              that.subtitulo =
                that.subtitulo + " está esperando la aprobación de su carga";
            }
          } else {
            that.mostrar = false;
          }
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>

<style scoped src="../../assets/css/components/tarjeta.css"></style>