<template>
  <div v-if="mostrar" class="tarjeta-parent">
    <div
      class="tarjeta tarjeta-body"
      style="background-color: #66c409"
      v-show="$usuario.rolClientes != null"
    >
      <div class="tarjeta-img">
        <img src="../../assets/img/tarjetas/Clientes.png" />
      </div>
      <div>
        <label class="tarjeta-titulo">{{ titulo }}</label>
        <label class="tarjeta-descripcion">{{ descripcion }}</label>
      </div>
      <img
        class="tarjeta-link"
        src="../../assets/img/botones/flecha.png"
        @click="ir()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "clientes",
  components: {},
  props: {},
  data() {
    return {
      mostrar: false,
      titulo: "",
      descripcion: "",
    };
  },
  created() {},
  mounted() {
    this.$eventHub.$on("update", () => this.chequearUsuario());
  },
  computed: {},
  methods: {
    ir() {
      let token = localStorage.getItem("token-landing");
      window.location.href = this.$mialta_url + "/landing/" + token;
    },
    chequearUsuario() {
      if (
        this.$usuario.rolClientes != null &&
        this.$usuario.rolClientes.detalle == "Distribuidor"
      ) {
        this.titulo = "Modifica tu perfil de cliente BASF cuando lo desees";
        this.descripcion =
          "Podrás editar o generar nuevas direcciones de entrega, actualizar tu información de contacto y más.";
        this.mostrar = true;
      } else {
        this.getClientes();
      }
    },
    getClientes() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/clientes")
        .then(function (response) {
          console.log(response);
          if (response.data != null && response.data.length > 0) {
            that.titulo =
              "¡Algunos clientes están esperando tu aprobación de alta!";
            that.descripcion =
              "Chekealos cuanto antes para que puedan seguir su proceso de revisión.";
            that.mostrar = true;
          } else {
            that.mostrar = false;
          }
        })
        .catch(function (response) {
          that.mostrar = false;
          console.log(response);
        });
    },
  },
};
</script>

<style scoped src="../../assets/css/components/tarjeta.css"></style>