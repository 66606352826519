<template>
  <div v-if="mostrar" class="tarjeta-parent">
    <div
      class="tarjeta"
      style="background-color: #ffaa00"
      v-show="$usuario.rolCC != null"
    >
      <div class="tarjeta-body">
        <div class="tarjeta-img">
          <img src="../../assets/img/tarjetas/CC.png" />
        </div>
        <div>
          <label class="tarjeta-titulo">Mi Cuenta Corriente</label>
          <label class="app-solo-mobile tarjeta-cc-pendiente">{{
            "Pendiente " +
              moneda +
              " " +
              (moneda == "ARS"
                ? formatImporteToString(pendienteARS)
                : formatImporteToString(pendienteUSD))
          }}</label>
          <div class="tarjeta-btn-container">
            <div
              class="tarjeta-btn"
              :class="{ 'tarjeta-btn-active': moneda == 'ARS' }"
              @click="moneda = 'ARS'"
            >
              <label>ARS</label>
            </div>
            <div
              class="tarjeta-btn"
              :class="{ 'tarjeta-btn-active': moneda == 'USD' }"
              @click="moneda = 'USD'"
            >
              <label>USD</label>
            </div>
            <label class="app-mobile-no tarjeta-cc-pendiente">{{
              "Pendiente " +
                moneda +
                " " +
                (moneda == "ARS"
                  ? formatImporteToString(pendienteARS)
                  : formatImporteToString(pendienteUSD))
            }}</label>
          </div>
        </div>
        <img
          class="tarjeta-link"
          src="../../assets/img/botones/flecha.png"
          @click="ir()"
        />
      </div>
      <div>
        <div class="tarjeta-row">
          <div class="tarjeta-cc-row">
            <label class="tarjeta-cc-label">{{ moneda + " a 30 Días" }}</label>
            <label class="tarjeta-cc-total">{{
              getMonto(
                moneda == "ARS"
                  ? cuenta.saldoTotalARS30
                  : cuenta.saldoTotalUSD30
              ) +
                " / " +
                getMonto(moneda == "ARS" ? pendienteARS : pendienteUSD)
            }}</label>
          </div>
          <div class="tarjeta-cc-barra">
            <div :style="'width: ' + getPorcentajeBarra('30') + '%;'"></div>
          </div>
        </div>
        <div class="tarjeta-row">
          <div class="tarjeta-cc-row">
            <label class="tarjeta-cc-label">{{ moneda + " a 60 Días" }}</label>
            <label class="tarjeta-cc-total">{{
              getMonto(
                moneda == "ARS"
                  ? cuenta.saldoTotalARS60
                  : cuenta.saldoTotalUSD60
              ) +
                " / " +
                getMonto(moneda == "ARS" ? pendienteARS : pendienteUSD)
            }}</label>
          </div>
          <div class="tarjeta-cc-barra">
            <div :style="'width: ' + getPorcentajeBarra('60') + '%;'"></div>
          </div>
        </div>
        <div class="tarjeta-row">
          <div class="tarjeta-cc-row">
            <label class="tarjeta-cc-label">{{ moneda + " a 90 Días" }}</label>
            <label class="tarjeta-cc-total">{{
              getMonto(
                moneda == "ARS"
                  ? cuenta.saldoTotalARS90
                  : cuenta.saldoTotalUSD90
              ) +
                " / " +
                getMonto(moneda == "ARS" ? pendienteARS : pendienteUSD)
            }}</label>
          </div>
          <div class="tarjeta-cc-barra">
            <div :style="'width: ' + getPorcentajeBarra('90') + '%;'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatImporteToString } from "@/assets/js/general.js";

export default {
  name: "cc",
  components: {},
  props: {},
  data() {
    return {
      mostrar: false,
      moneda: "ARS",
      pendienteARS: "",
      pendienteUSD: ""
    };
  },
  created() {},
  mounted() {
    this.getCuenta();
  },
  computed: {},
  methods: {
    formatImporteToString(importe) {
      return formatImporteToString(importe);
    },
    ir() {
      let token = localStorage.getItem("token");
      if (this.$dev) {
        window.location.href =
          "https://dev.micuenta.basf.com.ar/cuenta-corriente/landing/" + token;
      } else {
        window.location.href =
          "https://micuenta.agro.basf.com/cuenta-corriente/landing/" + token;
      }
    },
    getMonto(monto) {
      const digits = 1;
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: " mil" },
        { value: 1e6, symbol: " mill" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      let item = lookup
        .slice()
        .reverse()
        .find(function(item) {
          return monto >= item.value;
        });
      return item
        ? (monto / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0";
    },
    getPorcentajeBarra(num) {
      let porcentaje = 0;
      if (this.cuenta != null) {
        switch (num) {
          case "30":
            porcentaje =
              ((this.moneda == "ARS"
                ? this.cuenta.saldoTotalARS30
                : this.cuenta.saldoTotalUSD30) *
                100) /
              (this.moneda == "ARS" ? this.pendienteARS : this.pendienteUSD);
            break;
          case "60":
            porcentaje =
              ((this.moneda == "ARS"
                ? this.cuenta.saldoTotalARS60
                : this.cuenta.saldoTotalUSD60) *
                100) /
              (this.moneda == "ARS" ? this.pendienteARS : this.pendienteUSD);
            break;
          case "90":
            porcentaje =
              ((this.moneda == "ARS"
                ? this.cuenta.saldoTotalARS90
                : this.cuenta.saldoTotalUSD90) *
                100) /
              (this.moneda == "ARS" ? this.pendienteARS : this.pendienteUSD);
            break;
        }
      }
      return porcentaje;
    },
    getCuenta() {
      let soldTo = localStorage.getItem("soldTo");
      if (soldTo != null && soldTo != "") {
        const that = this;
        this.$axios
          .get(this.$localurl + "/api/cuentas/self/" + soldTo)
          .then(function(response) {
            that.mostrar = true;
            that.cuenta = response.data;
            that.pendienteARS =
              that.cuenta.saldoTotalARS30 +
              that.cuenta.saldoTotalARS60 +
              that.cuenta.saldoTotalARS90;
            that.pendienteUSD =
              that.cuenta.saldoTotalUSD30 +
              that.cuenta.saldoTotalUSD60 +
              that.cuenta.saldoTotalUSD90;
          })
          .catch(function(response) {
            that.mostrar = false;
            console.log(response);
          });
      } else {
        this.mostrar = false;
      }
    }
  }
};
</script>

<style scoped src="../../assets/css/components/tarjeta.css"></style>
